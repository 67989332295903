<div class="wrapper row0 container-new">
    <div id="topbar" class="hoc clear">
        <!-- ################################################################################################ -->
        <div class="fl_left">
            <ul class="nospace">
                <li><a href="index.html"><i class="fas fa-home fa-lg"></i></a></li>
                <!-- <li><a href="#">ഞങ്ങളെക്കുറിച്ചു</a></li> -->
                <!-- <li><a href="#">Contact</a></li> -->
                <li id="loginBtn"><a href="https://www.afctcr.com/afc-parish-login" target="_blank">ലോഗിൻ</a></li>
                <li id="registerBtn"><a href="javascript:void(0)">രജിസ്റ്റർ</a></li>
            </ul>
        </div>
        <div class="fl_right">
            <ul class="nospace">
                <!-- <li><i class="fas fa-phone rgtspace-5"></i> +91 9447-769815</li> -->
                <li><a href="mailto:info@afctcr.com"> <i class="fas fa-envelope rgtspace-5"></i>info@afctcr.com</a>
                </li>
            </ul>
        </div>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper row1 container-new">
    <div id="wrapper" class="mobile-menu-div">
        <div id="menuBtn" (click)="changeMenuVisibility()"><i class="fa fa-bars"></i></div>
        <div id="home-div" *ngIf="isOpenMenu"><a [routerLink]="['/home']">
                <h4> <strong>ഹോം</strong></h4>
            </a></div>
        <nav *ngIf="isOpenMenu" id="myMenu">
            <ul>
                <li>
                    <h4> <strong><u>EWS Reservation</u> </strong></h4>
                </li>
                <li class="cursor-pointer" *ngFor="let department of ewsDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li>
                <li>
                    <h4> <strong><u>പദ്ധതികൾ</u> </strong></h4>
                </li>
                <li class="cursor-pointer" *ngFor="let department of schemeDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li>
                <li>
                    <h4> <strong><u>സ്‌കോളർഷിപ്പുകൾ</u> </strong></h4>
                </li>
                <li class="cursor-pointer" *ngFor="let department of scholarshipDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li>
                <!-- <li>
                    <h4> <strong><u>സർട്ടിഫിക്കറ്റുകൾ</u> </strong></h4>
                </li>
                <li class="cursor-pointer" *ngFor="let department of certificateDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li> -->
                <li>
                    <h4> <strong><u>E-District Services</u> </strong></h4>
                </li>
                <li class="cursor-pointer" *ngFor="let department of edistrictDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li>
                <li (click)="navigatetoDepartment(notificationsDepartmentList[0])">
                    <h4> <strong><u>നോട്ടിഫിക്കേഷനുകൾ</u> </strong></h4>
                </li>
                <li (click)="navigateToPhotoGallery()">
                    <h4> <strong><u>Photo Gallery</u> </strong></h4>
                </li>
                <!-- <li class="cursor-pointer" *ngFor="let department of notificationsDepartmentList"
                    (click)="navigatetoDepartment(department)">
                    <a>{{department.departmentName}}</a>
                </li> -->
            </ul>
        </nav>
    </div>


    <header id="header" class="hoc clear heading-div">
        <!-- ################################################################################################ -->
        <div id="logo" class="one_half first first-div-to ">
            <h1 class="logoname"><a [routerLink]="['/home']"><span>അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ</span></a></h1>
        </div>
        <div class="one_half last phone-email-id-div">
            <ul class="nospace clear">
                <li class="one_half first">
                    <div class="block clear"><i class="fas fa-phone"></i> <span><strong class="block">വിളിക്കുക</strong>
                            <span> +91 7902713325<img class="whatsapp-image" src="./assets/images/whatsApp.png"
                                    alt=""></span>
                            +91 7909293325</span> </div>
                </li>
                <li class="one_half">
                    <div class="block clear"><i class="far fa-clock"></i> <span><strong class="block"> തിങ്കൾ -
                                ശനി</strong> 10:00am - 05:00pm</span> </div>
                </li>
            </ul>
        </div>
        <!-- ################################################################################################ -->
    </header>
    <nav id="mainav" class="hoc clear mainav-menu">
        <ul class="clear">
            <li class="active"><a [routerLink]="['/home']">ഹോം</a></li>
            <li><a class="drop" href="javascript:void(0)">EWS Reservation</a>
                <ul>
                    <li class="cursor-pointer" *ngFor="let department of ewsDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul>
            </li>
            <li><a class="drop" href="javascript:void(0)">പദ്ധതികൾ</a>
                <ul>
                    <li class="cursor-pointer" *ngFor="let department of schemeDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul>
            </li>
            <li><a class="drop" href="javascript:void(0)">സ്‌കോളർഷിപ്പുകൾ</a>
                <ul>
                    <li class="cursor-pointer" *ngFor="let department of scholarshipDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul>
            </li>
            <!-- <li><a class="drop" href="javascript:void(0)">സർട്ടിഫിക്കറ്റുകൾ</a>
                <ul>
                    <li class="cursor-pointer" *ngFor="let department of certificateDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul>
            </li> -->
            <li><a class="drop" href="javascript:void(0)">E-District Services</a>
                <ul>
                    <li class="cursor-pointer" *ngFor="let department of edistrictDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul>
            </li>
            <li><a class="drop" href="javascript:void(0)"
                    (click)="navigatetoDepartment(notificationsDepartmentList[0])">നോട്ടിഫിക്കേഷനുകൾ</a>
                <!-- <ul>
                    <li class="cursor-pointer" *ngFor="let department of notificationsDepartmentList"
                        (click)="navigatetoDepartment(department)">
                        <a>{{department.departmentName}}</a>
                    </li>
                </ul> -->
            </li>
            <li><a class="drop" href="javascript:void(0)" (click)="navigateToPhotoGallery()">Photo Gallery</a>
        </ul>
    </nav>
</div>